.pagination-dis-mobile {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 100px;
  width: 100%;
}

.pagination-dis-desktop {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 100px;
  right: 65px;
  width: 80%;
}
