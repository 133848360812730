.more-space {
    // .MuiPaper-root {
    .MuiButtonBase-root {
      background-color: #fff4dc;
    }
    .MuiExpansionPanelSummary {
        background-color: #fff4dc ;
    }
}

.StyledTableCell {
    background-color:#ff9c04;
    color: white;
    border-color: #ff9c04;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-top:7px;
    text-wrap: nowrap;
  }

  .styleCell {
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
    text-wrap: nowrap;
  }

  .titleContent {
    background-color:#faf2e8;
    font-size: 14px;
    color: #ff9800;
    border-color: #FEB15B;
    padding-bottom: 7px;
    padding-top:7px;
    font-weight: 700;
  }


  .space {
    .MuiExpansionPanelDetails-root {
        padding-bottom: 10px;
    }
  }

  .fieldstyle {
    .MuiInputBase-root {
        height: 30px;
        // padding-top: 3px;
        .MuiInputBase-input{
            height: 0px;
        }
    }
  }

  .controllerStyle {
    // .MuiAutocomplete-root {
    //     place-content: center;
    // }
    .MuiAutocomplete-hasPopupIcon{
        place-content: center;
    }
  }