.outline-new-design {
  .MuiInputBase-input {
    padding: 8px 8px;
    text-align: right;
    font-size: 12px;
    text-align: left;
  }
}

.qty-input-new-wid {
  display: flex;
  width: 100%;
  @extend .outline-new-design;
}

.index-padding-wid {
  padding: 6px 8px;
  place-self: center;
  overflow: visible;
}

.table-header {
  padding: 6px 12px;
  margin-bottom: 0px;
  background-color: #ededed;
  .MuiListItem-root .MuiListItemText-primary {
    display: unset;
  }
}

.MuiExpansionPanelDetails-root {
  padding: 8px;
  background: #f6f6f6;
  flex-wrap: wrap;
  border-top: 1px solid #e6e6e6;
}

.text-end {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}

/* -------------------------------------------- */
/*                 JOURNAL ITEM                 */
/* -------------------------------------------- */
.outlined-disabled {
  .MuiInputBase-root.Mui-disabled {
    color: #212121;
    background-color: #f5f5f5;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #212121;
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 10px;
  }
}

.table-wrap .table-listItem {
  box-shadow: none;
  margin-bottom: 0px;
  padding: 6px 12px;
  background: #f6f6f6;
}

.DropDownBottom {
  outline: 0;
  border-width: 0 0 2px;
}

.maxContentDate {
  .MuiFormLabel-root{
  inline-size: max-content;
}
}