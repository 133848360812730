.Font-Recon {
  font-size: 30px;
  font-weight: 600;
  left:46px;
  position:absolute;

}
.outlined-disabled {
    .MuiInputBase-root.Mui-disabled {
      color: #212121;
      background-color: #f5f5f5;
    }
    .MuiFormLabel-root.Mui-disabled {
      color: #212121;
    }
  
    .MuiOutlinedInput-input {
      padding: 10.5px 10px;
    }
  }
  
  .DropDownBottom {
    outline: 0;
    border-width: 0 0 2px;
  }
  
  .MuiExpansionPanelDetails-root {
    padding: 8px;
    background: #f6f6f6;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
  }
  .table-wrap .table-listItem {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 6px 12px;
    background: #f6f6f6;
  }
  
  //new
  
  .outline-new-design {
    .MuiInputBase-input {
      padding: 8px 8px;
      text-align: right;
      font-size: 12px;
      text-align: left;
    }
  }
  
  .qty-input-new-wid {
    display: flex;
    width: 100%;
    @extend .outline-new-design;
  }
  
  .index-padding-wid {
    padding: 6px 8px;
    place-self: center;
    overflow: visible;
  }
  
  .table-header {
    padding: 6px 12px;
    margin-bottom: 0px;
    background-color: #ededed;
    .MuiListItem-root .MuiListItemText-primary {
      display: unset;
    }
  }
  .text-end {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .text-right {
    text-align: right;
  }

  .padding-field {
    .MuiSelect-outlined.MuiSelect-outlined {
      padding-top: 3px;
      padding-left: 10px;
      padding-bottom: 3px;
      padding-right: 3px;
      font-size: 10px;
    }
  }
  