.input-section {
  display: flex;
  width: 100%;
}

.noColor {
  .MuiTreeItem-content {
    background-color: transparent;
    .MuiTreeItem-root.Mui-selected {
      background-color: transparent;
      .MuiTreeItem-content {
        .MuiTreeItem-label {
          background-color: transparent;
        }
      }
    }
  }
  .MuiTreeItem-root.Mui-selected {
    background-color: transparent;
    .MuiTreeItem-content {
      .MuiTreeItem-label {
        background-color: transparent;
      }
    }
  }
}
