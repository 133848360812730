.header-or {

    &--company {
        padding: 10px;
        padding-top: 30px;
        text-align: left;
    }

    &--company-title {
        font-size: medium;
        font-weight: bold;
        margin: 0 !important;
    }

    &--company-address {
        font-size: x-small;
        margin: 0 !important;
    }

    &--company-tax-tel {
        font-size: x-small;
        margin-top: 5px 0px 0px 0px;
    }

    &--title {
        border-style: solid solid none solid;
        text-align: center;
        margin: 0 !important;
        text-transform: uppercase;
        font-weight: bold;
    }

    &--doc {
        border-style: solid solid solid solid;
        text-align: left;
        margin: 0 !important;
        text-transform: uppercase;
        padding-left: 10px;
    }

    &--col-r {
        float: right;
        width: 30%;
    }

    &--fax {
        padding-left: 10px;
    }
}

.body-or {
    &--body {
        width: 100%;
        margin-top: 40px;
        padding-left: 10px;
    }

    &--col-l-title {
        float: left;
        width: 30%;
    }

    &--col-l-label {
        float: left;
        width: 70%;
    }

    &--col-l-desc {
        float: left;
        width: auto;
        font-weight: bold;
    }

    &--col-l-label-buyer {
        float: left;
        width: 30%;
    }

    &--col-r {
        float: right;
        width: 30%;
    }

    &--col-date-title {
        float: right;
        width: 10%;
    }

    &--col-date-label {
        float: right;
        width: 20%;
    }

    &--m-2 {
        margin: 2px !important;
    }
}

.footer-or {

    &--center {
        font-size: 5;
        right: 150;
        bottom: 10px;
        padding: 185px 10px 10px 10px,
    }

    &--signature {
        width: 30%;
        border-top: 0.5px solid;
        text-align: 'left';
    }
}