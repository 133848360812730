.outlined-disabled {
  .MuiInputBase-root.Mui-disabled {
    color: #212121;
    background-color: #f5f5f5;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #212121;
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 10px;
  }
}
.outlined-input {
  .MuiInputBase-input {
    padding: 4px 8px;
    text-align: right;
    font-size: 12px;
    border: 1.5px solid #0019d8;
    border-radius: 3px;
  }
}
.input-outlined {
  display: flex;
  width: 100%;
  @extend .outlined-input;

  .input-price {
    .MuiInputBase-root {
      width: 90px;
      margin: 0px 0px 0px auto;
    }
  }
}
.disabled {
  .MuiInputBase-root.Mui-disabled {
    color: #212121;
    background-color: #f5f5f5;
  }
  .MuiInputBase-input {
    padding: 4px 8px;
    text-align: right;
    font-size: 12px;
    // border: 1.5px solid #0019d8;
    border-radius: 3px;
  }
}

.disabled-edit-input {
  .MuiInputBase-root.Mui-disabled {
    color: #212121;
    font-weight: 500;
    background-color: #f5f5f5;
  }
  .MuiInput-root {
    border-radius: 3px;
    border: 1.5px solid #f5f5f5;
    text-align: right;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 8px;
  }
  .MuiInputBase-input {
    padding: 4px 8px;
    text-align: right;
    font-size: 12px;
    // border: 1.5px solid #0019d8;
  }
}
.uom-input {
  display: flex;
  width: 100%;
  @extend .outlined-input;
}

.qty-input {
  display: flex;
  width: 100%;
  @extend .outlined-input;
}
.price-input {
  display: flex;
  width: 100%;
  min-width: 50%;
  @extend .outlined-input;
}

.disabled-input {
  display: flex;
  width: 100%;
  min-width: 50%;
  @extend .disabled;
}

.disabled-edit {
  display: flex;
  width: 100%;
  cursor: pointer;
  min-width: 50%;
  @extend .disabled-edit-input;
}

.helper {
  .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-error.MuiFormHelperText-filled {
    white-space: break-spaces;
  }
  .MuiFormHelperText-contained {
    margin-left: 0;
    margin-right: 0;
  }
}
