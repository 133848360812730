.rm-padding {
  margin: -8px -12px 0px !important;
  flex: 1;
  width: 100%;
}
.table-wrap {
  .table-header {
    padding: 6px 12px;
    margin-bottom: 0px;
    background-color: #ededed;
    .MuiListItem-root .MuiListItemText-primary {
      display: unset;
    }
  }
  .table-content {
    padding: 6px 12px;
    width: 100%;
    .delete-icon {
      .MuiIconButton-root {
        padding: 10px;
      }
    }
  }
  .table-listItem {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 6px 12px;
  }
  .num-width {
    width: 20px;
  }
  .md-width {
    width: 30px;
  }
  .right-width {
    width: 100px;
  }
  .md-right-width {
    width: 80px;
  }
  .md-space {
    width: 100px !important;
  }

  .right-space {
    width: 100px !important;
  }

  .empty-space {
    width: 40px;
  }

  .right-text {
    text-align: right;
  }
  .center-text {
    text-align: center;
  }
}
.outlined-input {
  .MuiInputBase-input {
    padding: 4px 8px;
    text-align: right;
    font-size: 12px;
    border: 1.5px solid #0019d8;
    border-radius: 3px;
    display: flex;
    width: 100%;
  }
}
.qty-input {
  @extend .outlined-input;
  text-align: right !important;
}

.year-padding {
  margin: 0px 0px 0px 0px !important;
  flex: 1;
  width: 100%;
}

// Dynamic Subheader
.align-self {
  align-self: center;
  font-weight: bold;
  font-size: 13px;
  color: #0c1c8f;
}

// circle

.circle {
  display: flex;
  width: 18px;
  height: 18px;
  color: white;
  background-color: orange;
  border-radius: 80%;
  align-items: center;
  flex-direction: column;
  font-size: smaller;
}

.calendar-icon {
  font: icon;
}

.border-width {
  &.MuiTextField-root {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-input {
        padding-right: 0%;
      }
    }
  }
}
