.rm-padding {
  margin: -8px -12px 0px !important;
  flex: 1;
  width: 100%;
}
.table-wrap {
  .table-header {
    padding: 6px 12px;
    margin-bottom: 0px;
    background-color: #ededed;
    .MuiListItem-root .MuiListItemText-primary {
      display: unset;
    }
  }
  .table-content {
    padding: 6px 12px;
    width: 100%;
    .delete-icon {
      .MuiIconButton-root {
        padding: 10px;
      }
    }
  }

  .MuiExpansionPanelDetails-root {
    padding: 8px;
    background: #f6f6f6;
    flex-wrap: wrap;
    border-top: 1px solid #e6e6e6;
  }
  
  .table-listItem {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 6px 12px;
  }
  .num-width {
    width: 20px;
  }
  .md-width {
    width: 30px;
  }
  .right-width {
    width: 100px;
  }
  .md-right-width {
    width: 80px;
  }
  .md-space {
    width: 100px !important;
  }

  .right-space {
    width: 100px !important;
  }

  .empty-space {
    width: 40px;
  }

  .right-text {
    text-align: right;
  }
  .center-text {
    text-align: center;
  }
}
.outlined-input {
  .MuiInputBase-input {
    padding: 4px 8px;
    text-align: right;
    font-size: 12px;
    border: 1.5px solid #0019d8;
    border-radius: 3px;
    display: flex;
    width: 100%;
  }
}
.qty-input {
  @extend .outlined-input;
  text-align: right !important;
}

.outline-new-design {
  .MuiInputBase-input {
    padding: 8px 8px;
    text-align: right;
    font-size: 12px;
    text-align: left;
  }
}

.qty-input-new-wid {
  display: flex;
  width: 100%;
  @extend .outline-new-design;
}

.table-wrap .table-listItem {
  box-shadow: none;
  margin-bottom: 0px;
  padding: 6px 12px;
  background: #f6f6f6;
}



// TOOLTIP BACKGROUND COLOR CSS

.greenTooltip .tooltip-inner {
  background-color: green;
}

.greenTooltip .arrow::before {
  border-top-color: green;
}