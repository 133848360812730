.cover-layer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: -0;
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}

.hidden-content {
  .card-form {
    box-shadow: none;
  }
}

.custom-date-reservation {
  .MuiOutlinedInput-input {
    padding: 12px 12px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
  }
}
