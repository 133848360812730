.radio-body-style {
    .MuiButtonBase-root{
        padding-top: 0px;
        padding-bottom: 0px;
        .MuiFormControlLabel-root{
            padding-top: 0px;
            padding-bottom: 0px;
            .MuiButtonBase-root{
                padding-bottom: 0px;
                padding-top: 0px;
                .MuiTypography-root{
                    font-size: 11px;
                    font-family: Poppins, sans-serif;
                    font-weight: 400;
                    padding: 0px;
                }
            }
        }
    }
}

.radio-text-style {
    .MuiTypography-root{
        font-size: 11px;
        font-family: Poppins, sans-serif;
        font-weight: 400;
        padding: 0px;
    }
}

.radio-menu-style {
    .MuiMenuItem-root {
        min-height: auto !important;  
      }
}

.radio-size {
    .MuiFormControlLabel-root {
        .MuiTypography-root{
            font-size: 14px;
        }
    }
}