.rm-padding {
  margin: -8px -12px 0px !important;
  flex: 1;
  width: 100%;
}
.MuiExpansionPanelDetails-root {
  padding: 8px;
  background: #f6f6f6;
  flex-wrap: wrap;
  border-top: 1px solid #e6e6e6;
}
.table-wrap .table-listItem {
  box-shadow: none;
  margin-bottom: 0px;
  padding: 6px 12px;
  background: #f6f6f6;
}
.table-wrap {
  .table-header {
    padding: 6px 12px;
    margin-bottom: 0px;
    background-color: #ededed;
    .MuiListItem-root .MuiListItemText-primary {
      display: unset;
    }
  }
  .table-content {
    padding: 6px 12px;
    width: 100%;
    .delete-icon {
      .MuiIconButton-root {
        padding: 10px;
      }
    }
  }
  .table-content-v2 {
    padding: 6px 0px;
    width: 100%;
    .delete-icon {
      .MuiIconButton-root {
        padding: 10px;
      }
    }
  }
  .table-listItem {
    box-shadow: none;
    margin-bottom: 0px;
    padding: 6px 12px;
  }
  .num-width {
    width: 20px;
  }
  .md-width {
    width: 30px;
  }
  .right-width {
    width: 100px;
  }
  .md-right-width {
    width: 80px;
  }
  .md-space {
    width: 100px !important;
  }

  .right-space {
    width: 100px !important;
  }

  .empty-space {
    width: 40px;
  }

  .right-text {
    text-align: right;
  }
  .center-text {
    text-align: center;
  }
}
.outlined-input {
  .MuiInputBase-input {
    padding: 4px 8px;
    text-align: left;
    font-size: 12px;
    border: 1.5px solid #0019d8;
    border-radius: 3px;
    display: flex;
    width: 100%;
  }
}
.qty-input {
  @extend .outlined-input;
  text-align: right !important;
}

.outline-new-design {
  .MuiInputBase-input {
    padding: 8px 8px;
    text-align: right;
    font-size: 12px;
    text-align: left;
  }
}

.mat-new {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiOutlinedInput-root {
        padding: 0;
        text-align: right;
        font-size: 12px;
        text-align: left;
      }
    }
  }
}

.qty-input-new {
  display: flex;
  width: 100%;
  @extend .outline-new-design;
}

.price-input-new {
  display: flex;
  width: 100%;
  // min-width: 50%;
  @extend .outline-new-design;
}

.index-padding {
  padding: 6px 6px;
  overflow: visible;
}

.table-display {
  display: flex;
  margin-top: 10px;
}

.div-parent-delete {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.div-delete {
  padding-bottom: 5px;
}

.outline-new-design {
  .MuiInputBase-input {
    padding: 8px 8px;
    text-align: right;
    font-size: 12px;
    text-align: left;
  }
}

.qty-input-new-wid {
  display: flex;
  width: 100%;
  @extend .outline-new-design;
}

/* -------------------------------------------- */
/*                 AUDIT ADJUSTMENT ITEM        */
/* -------------------------------------------- */
.outlined-disabled {
  .MuiInputBase-root.Mui-disabled {
    color: #212121;
    background-color: #f5f5f5;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #212121;
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 10px;
  }
}

.DropDownBottom {
  outline: 0;
  border-width: 0 0 2px;
}

.outline-new-design {
  .MuiInputBase-input {
    padding: 8px 8px;
    text-align: right;
    font-size: 12px;
    text-align: left;
  }
}

.qty-input-new-wid {
  display: flex;
  width: 100%;
  @extend .outline-new-design;
}

.index-padding-wid {
  padding: 6px 8px;
  place-self: center;
  overflow: visible;
}

.table-header {
  padding: 6px 12px;
  margin-bottom: 0px;
  background-color: #ededed;
  .MuiListItem-root .MuiListItemText-primary {
    display: unset;
  }
}
.text-end {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-right {
  text-align: right;
}


.tooltip [class$="inner"] {
  background-color: background-color;
  border: 1px solid background-color;
}

.tooltip [class$="arrow"] {
  border-top: 5px solid background-color !important;
}
