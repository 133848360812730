.react-tel-input .form-control:focus {
  border-color: darkgrey;
  box-shadow: none;
}
.react-tel-input .form-control {
  padding: 16px 12px 8px 58px;
}

.react-tel-input .selected-flag {
  margin-top: 3px;
}

.react-tel-input .form-control.invalid-number:focus {
  box-shadow: none;
  border-bottom: '2px solid #900';
  border: 'none';
}

.react-tel-input .special-label {
  left: -5px;
  color: #878787;
  font-weight: 400;
  font-family: 'Poppins';
  font-size: 12px;
}

.special-label-error {
  .react-tel-input .special-label {
    color: #e53935;
  }
}

.react-tel-input .form-control.invalid-number:focus {
  box-shadow: none;
  border-bottom: '.1px solid #900';
  border: 'none';
}
.react-tel-input .form-control.invalid-number {
  box-shadow: none;
  border-bottom: '.1px solid #900';
  border-color: darkgrey;
  border: 'none';
}
