@import './input.scss';
@import './table.scss';

.margin-override {
  @media screen and (max-width: 600px) {
    margin: -70px 0px !important;
  }

  margin: -95px -24px !important;
}
